<!-- 配电图 -->
<template>
  <div id="distribution">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>配电图</span>
      </div>
      <iframe
      v-if="stationItem.type==1"
      :src="stationItem.stationUrl"
      width="1600px" height="1200px"></iframe>
        <!-- 箱变 -->
      <div class="column"  v-if="stationItem.type==2" >
          <img class="bgImg" :src="stationItem.stationUrl" alt="">
          <div class="openIcon">
            <img v-if="type==1" src="../../assets/images/open/open.png" alt="">
            <img v-if="type==2" src="../../assets/images/open/shut.png" alt="">
          </div>
          <div class="text">
            <div class="" style="color: #AAAB8D; margin-bottom: 5px;">Ia:{{ showText.onceIa }}A</div>
            <div class="" style="color: #1CA862; margin-bottom: 5px;">Ib:{{ showText.onceIb }}A</div>
            <div class="" style="color: #862943; margin-bottom: 5px;">Ic:{{ showText.onceIc }}A</div>
            <div class="" style="color: #aed6ff;">P:{{ showText.temperature }}°C</div>
          </div>
      </div>
      <!-- 柱变&强电间 -->
      <div class="column"  v-if="stationItem.type==3||stationItem.type==4" >
          <img class="bgImg" :src="stationItem.stationUrl" alt="">
          <div class="openIcon3">
            <img v-if="type==1" src="../../assets/images/open/open.png" alt="">
            <img v-if="type==2" src="../../assets/images/open/shut.png" alt="">
          </div>
          <div class="text3">
            <div class="" style="color: #AAAB8D; margin-bottom: 5px;">Ia:{{ showText.onceIa }}A</div>
            <div class="" style="color: #1CA862; margin-bottom: 5px;">Ib:{{ showText.onceIb }}A</div>
            <div class="" style="color: #862943; margin-bottom: 5px;">Ic:{{ showText.onceIc }}A</div>
            <div class="" style="color: #aed6ff;">P:{{ showText.temperature }}°C</div>
          </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import  {emissionsCarbonApi}  from '@/api/emissionsCarbon.js'
import { get } from 'jquery';
export default {
  name: "",

  data() {
    return {
        stationNumber:'',
        stationItem:{
          stationUrl:''
        },
        type:1,
        showText:{
          onceIa:'',
          onceIb:'',
          onceIc:'',
          temperature:'',
        },     
    };
  },

  
  mounted() {
    
  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '配电图')
    this.$store.commit("selectChildren", "");
    this.stationNumber = sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0919-01'
    
    this.getUrl()
  },

  methods: {
    getUrl(){
      let obj ={
        stationNumber:this.stationNumber
      }
      emissionsCarbonApi.getStationUrl(obj).then(res=>{
        if(res.code==200 && res.data){
          this.stationItem=res.data
          console.log(res.data,'aaaaaaa');

          if(res.data.stationInfoUrlPositionPoint){
            this.showText=res.data.stationInfoUrlPositionPoint
            const positions = [
          res.data.stationInfoUrlPositionPoint.onceUa,
          res.data.stationInfoUrlPositionPoint.onceUb,
          res.data.stationInfoUrlPositionPoint.onceUc
        ];
            if(positions.some(position => position && position !== 0)){
              this.type=2
            }else{
              this.type=1
            }
          }else{
            this.type=1

          }
        }
      })

    }
   
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
}
#distribution {
  width: 100%;
  min-width: 1621px;
  min-height: 903px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  padding: 21px;
  box-sizing: border-box;
}
.query-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form :deep().el-form-item__label {
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
}
.form:deep() input {
  width: 202px;
  height: 35px;
  border: 1px solid #09295b;
  background-color: #041c4c;
  color: #aed6ff;
  font-size: 13px;
}
.form:deep().el-icon-search {
  color: #1f49b5;
}
.update-time {
  display: flex;
  align-items: center;
  color: #aed6ff;
  font-size: 12px;
}
.update-time span::after {
  content: ":";
  margin: 0 3px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}
.dialog-content {
  display: flex;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
/* tabs */
.tabs {
  display: flex;
}
.tabs li {
  width: 151px;
  height: 47px;
  border-radius: 3px;
  background-color: #09244f;
  font-family: Microsoft YaHei;
  color: #c9d4f1;
  font-size: 14px;
  text-align: center;
  line-height: 47px;
}
.content-left {
  width: 303px;
  height: 610px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
}
.tabs .select {
  background-color: #1c3d7d;
  color: #ffc74a;
}

.search :deep() input {
  width: 262px;
  height: 35px;
  border: 1px solid #09295b;
  background-color: #09244d;
  color: #aed6ff;
  font-size: 13px;
  margin: 18px 0 16px 20px;
}
.select {
  width: 262px;
  height: 474px;
  padding: 2px 2px 2px 2px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  margin-left: 20px;
}
.select li {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
.select input {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: #176ad2;
}
.select span {
  color: #aed6ff;
  font-size: 14px;
}
.content-right {
  width: 837px;
  height: 610px;
  padding: 16px 0 0 21px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
  margin-left: 15px;
}
.query{
  display: flex;
  margin-bottom: 14px;
}
.query :deep() .el-input{
  width: 347px;
}
.query :deep() input {
  width: 347px;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #09295b;
  background-color: #09244d;
  font-size: 13px;
  color: #aed6ff;

}

.query :deep() button{
  width: 68px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid rgba(121, 121, 121, 0);
  background-color: #176ad2;
  box-sizing: border-box;
  color: #fdfdfd;
  text-align: center;
  line-height: 36px;
  padding: 0;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}
/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;
  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.column{
  width: 1450px;
  height: 750px;

  position: relative;
}
.column .bgImg{
  width: 1450px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 0;
}
.openIcon{
  position: absolute;
  top: 476px;
  left: 148px; 
}
.text{
  position: absolute;
  top: 188px;
  left: 345px;
  /* border: 1px solid #fff; */
}
.openIcon3{
  position: absolute;
  top: 464px;
  left: 436px; 
}
.text3{
  position: absolute;
  top: 411px;
  left: 472px;
  /* border: 1px solid #fff; */
}

</style>
